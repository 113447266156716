import Head from "next/head";
import React, { useState } from "react";
import { Menu } from "../components/Menu";

import { useDimensions } from "../components/useDimensions";
import { Loading } from "../components/Homepage/Loading";
import { Mobile } from "../components/Homepage/Mobile";
import { MOBILE_BREAKPOINT, UHD_WIDTH_BREAKPOINT } from "../constants/layout";
import { Desktop } from "../components/Homepage/Desktop";
import { UHDDesktop } from "../components/Homepage/UHDDesktop";

enum Mode {
  UHD = "UHD",
  DESKTOP = "desktop",
  MOBILE = "mobile",
  LOADING = "loading",
}
const getMode = (width: number | null): Mode => {
  if (width === null) return Mode.LOADING;
  if (width > UHD_WIDTH_BREAKPOINT) return Mode.UHD;
  if (width > MOBILE_BREAKPOINT) return Mode.DESKTOP;
  return Mode.MOBILE;
};

export default function Home() {
  // // FIXME: use this: https://coldiary.github.io/react-image-mapper/
  // // FIXME: maintained version: https://www.npmjs.com/package/react-img-mapper
  // useImageMapResizer();

  const { width } = useDimensions();
  const [isDark, setIsDark] = useState(true);

  const handelToggleDarkMode = () => {
    setIsDark((p) => !p);
  };

  const Navbar = () => {
    return (
      <>
        <div className="absolute z-50 flex items-center top-2 sm:top-10 left-6 sm:left-10">
          <Menu onToggleDarkMode={handelToggleDarkMode} />
        </div>
      </>
    );
  };

  const modes = {
    [Mode.UHD]: <UHDDesktop isDark={isDark} />,
    [Mode.DESKTOP]: <Desktop isDark={isDark} />,
    [Mode.MOBILE]: <Mobile isDark={isDark} />,
    [Mode.LOADING]: <Loading />,
  };

  return (
    <div
      className={`
      flex flex-col items-center justify-center 
      h-screen 
      ${isDark ? "dark" : ""}
    `}
    >
      <Head>
        <title>Polarity Works</title>
        <meta
          name="description"
          content="Polarity Works, creators of BT60 (BTCKP), CRBN, ROTR."
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&family=Poppins:wght@200;300;500;700;800&display=swap"
          rel="stylesheet"
        />
      </Head>

      <Navbar />

      {modes[getMode(width)]}
    </div>
  );
}
