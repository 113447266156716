import Link from "next/link";

interface Dot {
  pos: { x: number; y: number } | null;
  href: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const Dot: React.FC<Dot> = ({
  pos,
  href,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (!pos) return null;
  return (
    <Link href={href}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ top: pos.y, left: pos.x }}
        className="absolute pointer-events-auto cursor-pointer"
      >
        <div className="absolute animate-ping opacity-75 h-3 w-3 rounded-full bg-gray-100"></div>
        <div className="absolute opacity-75 h-3 w-3 rounded-full bg-gray-200"></div>
      </div>
    </Link>
  );
};
