import React from "react";
import { Data } from "../constants/data";
import Link from "next/link";

interface Props {
  data: Data | null;
}
export const Card: React.FC<Props> = ({ data }) => {
  return (
    <div
      className={`
        h-80 select-none
        transition-all duration-500
        bg-gray-100 rounded-xl shadow-xl
        ${
          !data
            ? "w-0 overflow-hidden text-transparent"
            : "w-card text-blue-800 "
        }
        ${!data?.url ? "cursor-default" : ""}
        `}
    >
      <div
        className={`group flex flex-col items-start h-full p-10 text-left no-underline`}
      >
        <Link href={data?.url || ""}>
          <a
            className={`flex flex-col grow ${
              !data?.url ? "pointer-events-none" : ""
            }`}
          >
            <h3 className="mb-4 text-3xl font-black tracking-widest font-title">
              {data?.title}
            </h3>
            <div className="h-full">
              <p
                className="m-0 text-base font-light tracking-wider line-clamp-5"
                style={{ width: 420 }}
              >
                {data?.description.long}
              </p>
            </div>
            <em
              className="duration-500 opacity-50 justify-self-end group-hover:opacity-100"
              style={{ width: 250 }}
            >
              {data?.url ? "click to see more" : "there is nothing more to see"}
            </em>
          </a>
        </Link>

        {data?.card?.additionalContent}
      </div>
    </div>
  );
};
