import Plus from "../../icons/plus.svg";
import Minus from "../../icons/minus.svg";
import React, { MouseEventHandler, useState } from "react";
import Link from "next/link";
import { Logo } from "../Logo";
import { BetaTag } from "../Tag";
import { KEYMAP_EDITOR_URL } from "../../constants/api";

const ICON_SIZE = 30;
interface MenuButtonProps {
  onClick?: MouseEventHandler;
  isOpen: boolean;
}
const MenuButton: React.FC<MenuButtonProps> = ({ onClick, isOpen }) => {
  return (
    <button
      title="menu"
      className="z-50 px-6 py-6 cursor-pointer focus:outline-none"
      type="button"
      onClick={onClick}
    >
      {isOpen ? (
        <Minus height={ICON_SIZE} width={ICON_SIZE} />
      ) : (
        <Plus
          className="animate-spin-slow"
          height={ICON_SIZE}
          width={ICON_SIZE}
        />
      )}
    </button>
  );
};

interface OverlayProps {
  show: boolean;
  onToggleDarkMode: () => void;
}
const MenuOverlay: React.FC<OverlayProps> = ({ show, onToggleDarkMode }) => {
  return (
    <div
      className={`absolute ${
        show ? "h-96 visible" : "h-0 invisible"
      }  w-full flex-col gap-4 overflow-hidden text-gray-50 tracking-widest sm:text-xl text-lg font-semibold bg-gray-900 px-12 top-20 left-0 rounded-md z-0 flex items-center justify-center transition-all duration-500`}
    >
      <a
        title="desktop view recommended."
        className="hover:text-yellow-300"
        href={KEYMAP_EDITOR_URL}
        target="_blank"
      >
        KEYMAP EDITOR <BetaTag />
      </a>

      <Link href="/rotr">
        <a className="hover:text-yellow-300">ROTR</a>
      </Link>
      <Link href="/rotrLite">
        <a className="hover:text-yellow-300">ROTR LITE</a>
      </Link>
      <Link href="/crbn">
        <a className="hover:text-yellow-300">CRBN</a>
      </Link>
      {/* <Link href="/something60">
        <a className="hover:text-yellow-300">something 60 i guess</a>
      </Link> */}
      <Link href="/btckp">
        <a className="hover:text-yellow-300">BT CKP</a>
      </Link>

      <a
        href="https://discord.gg/nBq8TShwP2"
        target="_blank"
        rel="noreferrer noopener"
        className="hover:text-purple-300"
      >
        DISCORD
      </a>
      <a
        href="https://github.com/PolarityWorks"
        target="_blank"
        rel="noreferrer noopener"
        className="hover:text-purple-300"
      >
        GITHUB
      </a>
      <p
        className="hover:text-yellow-300 cursor-pointer"
        onClick={onToggleDarkMode}
      >
        TOGGLE DARK MODE
      </p>
    </div>
  );
};

interface Props {
  onToggleDarkMode: () => void;
}
export const Menu: React.FC<Props> = ({ onToggleDarkMode }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <div
        className="flex items-center"
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <Logo />
        <MenuButton isOpen={navbarOpen} />
      </div>

      <MenuOverlay show={navbarOpen} onToggleDarkMode={onToggleDarkMode} />
    </>
  );
};
