import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import { DATA, Product } from "../../constants/data";
import { Card } from "../Card";
import { useImageMapResizer } from "../useImageMapResizer";

export const UHDDesktop = ({ isDark }: { isDark: boolean }) => {
  // FIXME: use this: https://coldiary.github.io/react-image-mapper/
  // FIXME: maintained version: https://www.npmjs.com/package/react-img-mapper
  useImageMapResizer(); // this only works with ONE image map

  const [selection, setSelection] = useState<Product | null>(null);
  const [debouncedSelection] = useDebounce(selection, 300);

  const [isLightLoaded, setIsLightLoaded] = useState(false);
  const [isDarkLoaded, setIsDarkLoaded] = useState(false);
  enum Mode {
    DARK = "dark",
    LIGHT = "light",
  }
  const images = {
    [Mode.DARK]: (
      <>
        <div
          className={`
          absolute z-50 pointer-events-none
          w-full h-full
          transition-all bg-gray-800 duration-500
          ${isDarkLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-700 animate-pulse font-title">
            LOADING
          </p>
        </div>
        <img
          key="image-dark"
          src="/images/dark_desktop_max.jpg"
          onLoad={() => setIsDarkLoaded(true)}
          useMap="#image-map"
          className="pointer-events-auto"
        />
      </>
    ),
    [Mode.LIGHT]: (
      <>
        <div
          className={`
          absolute z-50 pointer-events-none
          w-full h-full
          transition-all bg-gray-100 duration-500
          ${isLightLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-300 animate-pulse font-title">
            LOADING
          </p>
        </div>
        <img
          key="image-light"
          src="/images/light_desktop_max.jpg"
          onLoad={() => setIsLightLoaded(true)}
          useMap="#image-map"
          className="pointer-events-auto"
        />
      </>
    ),
  };

  return (
    <main
      className="relative w-screen h-screen overflow-hidden"
      style={{ backgroundColor: isDark ? "#393943" : "#c3c2c7" }}
    >
      <div
        onMouseEnter={() => setSelection(debouncedSelection)}
        onMouseLeave={() => setSelection(null)}
        className="absolute z-50 bottom-5 right-5"
      >
        <Card data={debouncedSelection ? DATA[debouncedSelection] : null} />
      </div>

      <div
        className="absolute z-0 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        style={{ width: 3840, height: 2160 }}
      >
        {images[isDark ? Mode.DARK : Mode.LIGHT]}
      </div>

      {/* 4k */}
      <map name="image-map">
        <area
          onMouseEnter={() => setSelection(Product.ROTR_LITE)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="ROTR LITE"
          title="ROTR LITE"
          href="/rotrLite"
          coords="1537,2925,1847,2716,1857,2589,1628,2421,1598,2319,1476,2253,1308,2269,1247,2365,1252,2452,1130,2538,1140,2655"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.ROTR)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="ROTR"
          title="ROTR"
          href="/rotr"
          coords="2330,2385,2320,2253,2046,2075,1990,2004,1867,1963,1750,1994,1689,2080,1521,2213,1527,2274,1593,2314,1633,2421,1984,2614"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.BTCKP)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="BT Common Keyboard Platform"
          title="BT CKP"
          href="/btckp"
          coords="3195,2645,4686,1806,4254,1495,2758,2299"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.SOON)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="coming soon"
          title="coming soon"
          href="#"
          coords="2524,2248,3949,1328,3959,1175,3409,778,1974,1694,1969,1841"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.CRBN)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="CRBN"
          title="CRBN"
          href="/crbn"
          coords="1791,1816,2895,1088,2900,961,2514,727,1409,1399,1399,1567"
          shape="poly"
        />
      </map>
    </main>
  );
};
