import { DATA, Product } from "../constants/data";
import { Dot } from "./Dot";

interface Props {
  setProduct: (product: Product | null) => void;
  data: { pos: { x: number; y: number }; product: Product }[];
}
export const Dots: React.FC<Props> = ({ data, setProduct }) => {
  return (
    <>
      {data.map((item) => {
        const product = DATA[item.product];

        return (
          <Dot
            key={product.url}
            pos={item.pos}
            href={product.url}
            onMouseEnter={() => setProduct(item.product)}
            onMouseLeave={() => setProduct(null)}
          />
        );
      })}
    </>
  );
};
