import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { DATA, Product } from "../../constants/data";
import { isRetinaDisplay } from "../../utils";
import { Card } from "../Card";
import { Dots } from "../Dots";
import { useImageMapResizer } from "../useImageMapResizer";

interface UrlOptions {
  "1x": string;
  "2x": string;
}
const getImageURL = (options: UrlOptions) => {
  if (isRetinaDisplay()) return options["2x"];
  return options["1x"];
};

export const Desktop = ({ isDark }: { isDark: boolean }) => {
  // FIXME: use this: https://coldiary.github.io/react-image-mapper/
  // FIXME: maintained version: https://www.npmjs.com/package/react-img-mapper
  useImageMapResizer(); // this only works with ONE image map

  const [selection, setSelection] = useState<Product | null>(null);
  const [debouncedSelection] = useDebounce(selection, 300);

  const [isLightLoaded, setIsLightLoaded] = useState(false);
  const [isDarkLoaded, setIsDarkLoaded] = useState(false);
  enum Mode {
    DARK = "dark",
    LIGHT = "light",
  }
  const images = {
    [Mode.DARK]: (
      <>
        <div
          className={`
          absolute z-50 pointer-events-none
          w-full h-full
          transition-all bg-gray-800 duration-500
          ${isDarkLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-700 animate-pulse font-title">LOADING</p>
        </div>
        <img
          key="image-dark"
          src={getImageURL({
            "1x": "/images/dark_desktop_1x.jpg",
            "2x": "/images/dark_desktop_2x.jpg",
          })}
          onLoad={() => setIsDarkLoaded(true)}
          useMap="#image-map"
          className="pointer-events-auto"
        />
      </>
    ),
    [Mode.LIGHT]: (
      <>
        <div
          className={`
          absolute z-50 pointer-events-none
          w-full h-full
          transition-all bg-gray-100 duration-500
          ${isLightLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-300 animate-pulse font-title">LOADING</p>
        </div>
        <img
          key="image-light"
          src={getImageURL({
            "1x": "/images/light_desktop_1x.jpg",
            "2x": "/images/light_desktop_2x.jpg",
          })}
          onLoad={() => setIsLightLoaded(true)}
          useMap="#image-map"
          className="pointer-events-auto"
        />
      </>
    ),
  };

  // first timer helpers, once user has hovered over a selection,
  // save to localstorage and never show again
  const [isNewbie, setIsNewbie] = useState<boolean>(localStorage.getItem("isNew") !== "false");
  useEffect(() => {
    if (selection !== null) {
      setTimeout(() => {
        setIsNewbie(false);
        localStorage.setItem("isNew", "false");
      }, 3000);
    }
  }, [selection]);
  const helpers = [
    {
      pos: { x: 770, y: 750 }, // first two coords in general
      product: Product.ROTR,
    },
    {
      pos: { x: 615, y: 860 },
      product: Product.ROTR_LITE,
    },
    {
      pos: { x: 590, y: 565 },
      product: Product.CRBN,
    },
    {
      pos: { x: 915, y: 763 },
      product: Product.BTCKP,
    },
  ];

  return (
    <main
      className="relative w-screen h-screen overflow-hidden"
      style={{ backgroundColor: isDark ? "#393943" : "#c3c2c7" }}
    >
      <div
        onMouseEnter={() => setSelection(debouncedSelection)}
        onMouseLeave={() => setSelection(null)}
        className="absolute z-50 bottom-5 right-5"
      >
        <Card data={debouncedSelection ? DATA[debouncedSelection] : null} />
      </div>

      <div
        className="absolute z-0 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        style={{ height: 1080, width: 1920 }}
      >
        {images[isDark ? Mode.DARK : Mode.LIGHT]}
      </div>

      {/* first timers only */}
      <div
        className="absolute transition-opacity duration-1000 z-0 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 pointer-events-none"
        style={{ height: 1080, width: 1920, opacity: isNewbie ? 100 : 10 }}
      >
        <Dots data={helpers} setProduct={setSelection} />
      </div>

      {/* 1080p and retina */}
      <map name="image-map">
        <area
          onMouseEnter={() => setSelection(Product.ROTR)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="ROTR"
          title="ROTR"
          href="/rotr"
          coords="663,877,782,799,784,751,678,690,661,665,622,655,588,663,566,680,556,697,507,731,509,758,527,773,532,794"
          shape="poly"
        />

        <area
          onMouseEnter={() => setSelection(Product.ROTR_LITE)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="ROTR LITE"
          title="ROTR LITE"
          href="/rotrLite"
          coords="380,885,378,841,414,816,414,787,427,765,460,753,492,751,521,765,534,797,536,812,619,866,621,900,514,973"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.SOON)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="sooooooon"
          title="coming soon"
          href="#"
          coords="845,751,1320,437,1326,398,1141,261,661,568,656,615"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.CRBN)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="CRBN FEATHERLIGHT"
          title="CRBN FEATHERLIGHT"
          href="/crbn"
          coords="458,515,453,482,833,241,972,327,975,361,600,605"
          shape="poly"
        />
        <area
          onMouseEnter={() => setSelection(Product.BTCKP)}
          onMouseLeave={() => setSelection(null)}
          target="_self"
          alt="BT Common Keyboard Platform"
          title="BT CKP"
          href="/btckp"
          coords="1065,885,1508,639,1374,537,935,773"
          shape="poly"
        />
      </map>
    </main>
  );
};
