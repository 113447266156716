import Link from "next/link";
import { MouseEventHandler, useEffect, useState } from "react";
import { DATA, Data, Product } from "../../constants/data";
import { isRetinaDisplay } from "../../utils";

interface Props {
  data: Data | null;
  pos: {
    top: number;
    left?: number;
    right?: number;
  };
  clampWidth?: number;
  rot?: number;
  onClick?: MouseEventHandler;
}
const Label: React.FC<Props> = ({ data, pos, clampWidth, rot, onClick }) => {
  return (
    <Link href={data?.url || "#"}>
      <a
        className="absolute flex flex-col p-4 text-gray-800 duration-500 cursor-pointer transi dark:text-gray-200"
        onClick={onClick}
        style={{
          top: pos.top,
          left: pos.left,
          right: pos.right,
          maxWidth: clampWidth,
        }}
      >
        <h1 className="text-5xl font-black tracking-wider font-title">
          {data?.title}
        </h1>
        <p className="pt-2 line-clamp-3">
          {data?.description.short || data?.description.long}
        </p>
        <em className="pt-3 opacity-60" style={{ width: 150 }}>
          {data?.url ? "tap for more" : ""}
        </em>
      </a>
    </Link>
  );
};

export const Mobile = ({ isDark }: { isDark: boolean }) => {
  const [isLightLoaded, setIsLightLoaded] = useState(false);
  const [isDarkLoaded, setIsDarkLoaded] = useState(false);

  // these are specially arranged renders, so there is a 'best viewing' boundary

  enum Mode {
    DARK = "dark",
    LIGHT = "light",
  }
  const images = {
    [Mode.DARK]: (
      <>
        <div
          className={`
          absolute z-50 
          w-full h-full 
          transition-all bg-gray-800 duration-500
          ${isDarkLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-700 animate-pulse font-title">
            LOADING
          </p>
        </div>
        <img
          key="image-dark"
          className="object-cover object-top w-full h-full"
          src={
            isRetinaDisplay()
              ? "/images/dark_mobile_2x.jpg"
              : "/images/dark_mobile_1x.jpg"
          }
          onLoad={() => setIsDarkLoaded(true)}
        />
      </>
    ),
    [Mode.LIGHT]: (
      <>
        <div
          className={`
          absolute z-50 
          w-full h-full 
          transition-all bg-gray-100 duration-500
          ${isLightLoaded ? "opacity-0" : "opacity-100"}
          flex items-center justify-center
          `}
        >
          <p className="tracking-widest text-gray-300 animate-pulse font-title">
            LOADING
          </p>
        </div>
        <img
          key="image-light"
          className="object-cover object-top w-full h-full"
          src={
            isRetinaDisplay()
              ? "/images/light_mobile_2x.jpg"
              : "/images/light_mobile_1x.jpg"
          }
          onLoad={() => setIsLightLoaded(true)}
        />
      </>
    ),
  };
  return (
    <main className="relative w-screen h-screen overflow-x-hidden">
      <div
        className="absolute z-0 transform -translate-x-1/2 -top-52 left-1/2"
        style={{ height: 1920, width: 640 }}
      >
        {images[isDark ? Mode.DARK : Mode.LIGHT]}
      </div>

      <div>
        <Label
          data={DATA[Product.ROTR]}
          pos={{ top: 160, right: 0 }}
          clampWidth={300}
        />
        <Label
          data={DATA[Product.SOON]}
          pos={{ top: 450, left: 0 }}
          clampWidth={300}
        />
        <Label
          data={DATA[Product.CRBN]}
          pos={{ top: 780, right: 0 }}
          clampWidth={200}
        />
        <Label
          data={DATA[Product.ROTR_LITE]}
          pos={{ top: 1030, left: 10 }}
          clampWidth={260}
        />
        <Label
          data={DATA[Product.BTCKP]}
          pos={{ top: 1320, right: 0 }}
          clampWidth={250}
        />
      </div>
    </main>
  );
};
